import { createRouter, createWebHistory } from "@ionic/vue-router";
import { getData } from "@/utils/api";
import { openToast } from "@/utils/toast";
import Detail from "@/views/DietDetail.vue";
import Diet from "@/views/Diet.vue";
import EditDiet from "@/views/EditDiet.vue";
import Home from "@/views/Home.vue";
import Ingredient from "@/views/Ingredient.vue";
import Login from "@/views/Login.vue";
import store from "@/store";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/diet/:id",
    name: "Diet",
    component: Diet,
  },
  {
    path: "/diet/:id/detail",
    name: "Detail",
    component: Detail,
  },
  {
    path: "/diet/:id/edit",
    name: "EditDiet",
    component: EditDiet,
  },
  {
    path: "/ingredient/:id",
    name: "Ingredient",
    component: Ingredient,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const clearStore = () => {
  store.commit("token/setToken", null);
  store.commit("user/setUser", {});
};

router.beforeEach(async (to, from, next) => {
  const homePageRouteNames = ["Login", "Home"];
  if (
    to.name !== from.name &&
    !(
      homePageRouteNames.includes(to.name) &&
      homePageRouteNames.includes(from.name)
    )
  ) {
    store.commit("loading/setIsLoading", true);
  }
  const { username } = store.getters["user/getUser"];
  const token = store.getters["token/getToken"];
  const query = `query isAuthorized {
    isAuthorized(
      username: "${username}"
    )
  }`;
  const allowsGuest = to.name === "Login";
  const sendToLogin = { name: "Login" };

  // Do not perform this check on login route
  if (allowsGuest && !username) return next();

  // If there is no username, redirect
  if (!username) {
    openToast("You must be logged in to access this page.", 3000);

    return next(sendToLogin);
  }

  // Check user is authorized
  const { data } = await getData(query);

  if (!data || (data && !data.isAuthorized)) {
    if (username && token) clearStore();
    openToast("You do not have permission to access this page.", 3000);

    return next(sendToLogin);
  }

  // If user navigates to login page, redirect
  if (allowsGuest) {
    return next({ name: "Home" });
  }

  return next();
});

export default router;
