<template>
  <ion-header>
    <ion-toolbar>
      <router-link v-bind:to="`/`">
        <ion-title color="dark">{{ pageTitle }}</ion-title>
      </router-link>
      <ion-buttons slot="start" v-show="$route.path != '/home'">
        <ion-back-button @click="back" defaultHref="/home"
          >Back</ion-back-button
        >
      </ion-buttons>
      <ion-button
        shape="round"
        slot="end"
        class="ion-margin"
        color="warning"
        @click="openMenu()"
        expand="full"
        >{{ initials }}</ion-button
      >
    </ion-toolbar>
  </ion-header>
</template>

<script>
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButton,
  IonButtons,
  IonBackButton,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { menuController } from "@ionic/vue";
import { mapGetters } from "vuex";

export default defineComponent({
  components: {
    IonToolbar,
    IonTitle,
    IonButton,
    IonHeader,
    IonButtons,
    IonBackButton,
  },
  setup() {
    const initials = ref();
    return {
      initials,
    };
  },
  mounted() {
    this.getInitials();
  },
  computed: {
    ...mapGetters("user", ["getUser"]),
  },
  methods: {
    openMenu() {
      menuController.open("app-menu");
    },
    back() {
      this.$router.go(-1);
    },
    getInitials() {
      let user = this.getUser;

      if (user.firstName && user.lastName) {
        this.initials = `${user.firstName[0]} ${user.lastName[0]}`;
      } else if (user.username) {
        this.initials = user.username[0];
      } else {
        this.initials = "Options";
      }
    },
  },
  props: ["pageTitle"],
});
</script>
