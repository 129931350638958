<template>
  <ion-page>
    <template v-if="data && ingredientInfo">
      <Header :pageTitle="`${data.ingredient.name}`" />
      <ion-content :fullscreen="true">
        <ion-segment mode="md" value="ingredient">
          <ion-segment-button
            @click="toggleIngredientPage('ingredient')"
            value="ingredient"
            color="dark"
          >
            <ion-label color="dark">Ingredient Details</ion-label>
          </ion-segment-button>
          <ion-segment-button
            @click="toggleIngredientPage('history')"
            value="ingredients"
          >
            <ion-label color="dark">History</ion-label>
          </ion-segment-button>
        </ion-segment>

        <div
          v-if="ingredientOrHistoryToggle === 'ingredient'"
          class="ion-padding"
        >
          <div v-for="(nutrient, key) in nutrients" :key="nutrient">
            <ion-card>
              <ion-card-content>
                <ion-grid class="cards">
                  <ion-row class="ion-align-items-center">
                    <ion-col size="6">{{ nutrient }}</ion-col>
                    <ion-col size="6">
                      {{ ingredientInfo[key].toFixed(3) }}%</ion-col
                    >
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </div>

          <div class="container">
            <ion-button
              v-if="data.ingredient.isLabTested === false"
              class="centerChild yellowText"
              @click="openModal"
              >Update Nutrition Information</ion-button
            >
          </div>
        </div>
        <div v-if="ingredientOrHistoryToggle === 'history'">
          <ion-card>
            <ion-card-content> History Coming Soon</ion-card-content>            
          </ion-card>
        </div>
      </ion-content>
    </template>
  </ion-page>
</template>

<script>
import { defineComponent, ref } from "vue";
import Header from "@/components/Header.vue";
import {
  IonPage,
  IonContent,
  IonSegmentButton,
  IonSegment,
  IonLabel,
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
} from "@ionic/vue";
import { mapGetters } from "vuex";
import { getData } from "@/utils/api.js";
import { openToast } from "@/utils/toast.js";
import UpdateNutritionModal from "@/components/UntestedIngredientModal.vue";
import loadingMixin from "@/mixins/loading";
import modalMixin from "@/mixins/modal";
import { NUTRIENTS } from "@/utils/constants";

export default defineComponent({
  mixins: [loadingMixin, modalMixin],
  name: "Ingredient",
  components: {
    IonPage,
    IonContent,
    Header,
    IonSegmentButton,
    IonSegment,
    IonLabel,
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
  },
  setup() {
    const data = ref(null);
    const ingredientInfo = ref(null);
    const dmInput = ref();
    const ingredientOrHistoryToggle = ref("ingredient");
    const nutrients = NUTRIENTS;
    return {
      ingredientOrHistoryToggle,
      dmInput,
      ingredientInfo,
      data,
      nutrients,
    };
  },
  beforeUpdate() {
    this.resetSegment();
  },
  computed: {
    ...mapGetters("farm", ["getFarmId"]),
  },
  async created() {
    await this.getIngredientInfo();
  },
  methods: {
    toggleIngredientPage(input) {
      this.ingredientOrHistoryToggle = input;
    },
    resetSegment() {
      this.toggleIngredientPage("ingredient");
    },
    async dryMatterOverride() {
      const mutation = `mutation {
        createDmOverride (
          input: {
            ingredientId: ${this.$route.params.id},
            value:  ${this.dmInput}
          }
        ) {
          id
          date
          value
          dryMatterType
        }
      }`;

      const { data, errors } = await getData(mutation);

      if (errors) {
        errors.forEach(({ message }) => {
          openToast(message, 2000);
        });
      }

      if (data && data.createDmOverride.value) {
        this.ingredientInfo.dm = data.createDmOverride.value * 100;
      }
      this.dmInput = "";
    },
    async getIngredientInfo() {
      const query = `query {
        ingredient (farmId: ${this.getFarmId}, ingredientId: ${this.$route.params.id}) {
          name
          isLabTested
          dm
          starch
          cp
          ndf
          analyses {
            date
          }
        }
      }`;

      const response = await getData(query);

      if (response) {
        this.data = response.data;
        this.ingredientInfo = { ...response.data.ingredient };
      }
    },
    async openModal() {
      const modal = await this.openModalMixin(
        UpdateNutritionModal,
        `Update ${this.data.ingredient.name}`,
        this.data,
        this.nutrients
      );

      modal.onDidDismiss().then((modalData) => {
        if (modalData && modalData.data) {
          const { data } = { ...modalData };

          for (const nutrient in data) {
            if (!data[nutrient]) {
              data[nutrient] = this.ingredientInfo[nutrient];
            }
          }

          this.updatedUntestedIngredient(data);
        }
      });
    },
    async updatedUntestedIngredient(modalData) {
      for (const nutrient in modalData) {
        if (modalData[nutrient] > 100 || modalData[nutrient] < 0.1) {
          openToast(`${nutrient} must be between 0.1 and 100 `);
          return;
        }
      }

      const mutation = `mutation {
        updateUntestedIngredient (
          input: {
            ingredientId: ${this.$route.params.id},
            dm: ${modalData.dm}
            cp: ${modalData.cp},
            ndf: ${modalData.ndf},
            starch: ${modalData.starch}
          }
        ) {
          id
          dm
          cp
          ndf
          starch
        }
      }`;

      const { data, errors } = await getData(mutation);

      if (errors) {
        errors.forEach(({ message }) => {
          openToast(message, 2000);
        });
      }

      if (data) {
        this.ingredientInfo = data.updateUntestedIngredient;
        openToast(
          `${this.data.ingredient.name} has been updated.`,
          2000,
          "warning"
        );
      }
    },
  },
});
</script>
