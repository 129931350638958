<template>
  <ion-page>
    <template v-if="data && dataCompositions">
      <Header :pageTitle="`Formula Details - ${data.diet.name}`" />

      <ion-content :fullscreen="true">
        <ion-segment mode="md" value="diet">
          <ion-segment-button @click="toggleDetailsPage('diet')" value="diet">
            <ion-label color="dark">Diet Details</ion-label>
          </ion-segment-button>
          <ion-segment-button
            @click="toggleDetailsPage('history')"
            value="ingredients"
          >
            <ion-label color="dark">History</ion-label>
          </ion-segment-button>
        </ion-segment>

        <div v-if="dietOrHistoryToggle === 'diet'">
          <ion-grid class="header">
            <ion-row class="ion-padding-start">
              <ion-col> </ion-col>
              <ion-col>
                <b>{{ data.diet.optimizationDate }}</b>
              </ion-col>
              <ion-col>
                <b>{{ data.diet.prescriptionDate }}</b>
              </ion-col>
              <ion-col />
            </ion-row>
            <ion-row class="ion-padding-start">
              <ion-col />
              <ion-col>Tuned</ion-col>
              <ion-col>Formula</ion-col>
              <ion-col>Target</ion-col>
            </ion-row>

            <div v-for="(nutrient, key) in nutrients" :key="nutrient.id">
              <div
                v-for="composition in dataCompositions"
                :key="composition.id"
              >
                <Nutrient
                  :nutrient="nutrient"
                  :composition="composition"
                  :nutrientKey="key"
                />
              </div>
            </div>
          </ion-grid>
        </div>

        <div v-if="dietOrHistoryToggle === 'history'">
          <ion-card>
               <ion-card-content> <ion-button class="greenText centerChild" @click="generateReport"
                >Download Tuning Report (PDF)
              </ion-button></ion-card-content>
              <ion-card-content> <ion-button class="greenText centerChild" @click="exportTuning"
                > Export Ingredient Tuning History (CSV)
              </ion-button></ion-card-content> 
              <ion-card-content> <ion-button class="greenText centerChild" @click="exportTuningCompo"
                > Export Ingredient Composition Tuning History (CSV)
              </ion-button></ion-card-content> 
          </ion-card>
        </div>
      </ion-content>
    </template>
  </ion-page>
</template>

<script>
import { defineComponent, ref } from "vue";
import Nutrient from "@/components/Nutrient.vue";
import Header from "@/components/Header.vue";
import {
  IonPage,
  IonContent,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
} from "@ionic/vue";
import { getData, exportTuningCSV, exportCompoCSV, getReport } from "@/utils/api";
import { mapGetters } from "vuex";
import loadingMixin from "@/mixins/loading";
import { NUTRIENTS } from "@/utils/constants.js";
import { openToast } from "@/utils/toast";

export default defineComponent({
  mixins: [loadingMixin],
  name: "DietDetail",
  components: {
    IonPage,
    IonContent,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonGrid,
    IonRow,
    IonCol,
    Header,
    Nutrient,
    IonCard,
    IonCardContent,
  },
  setup() {
    const data = ref(null);
    const dataCompositions = ref(null);
    const dietOrHistoryToggle = ref("diet");

    const nutrients = NUTRIENTS;
    return {
      dietOrHistoryToggle,
      data,
      dataCompositions,
      nutrients,
    };
  },
  computed: {
    ...mapGetters("farm", ["getFarmId"]),
  },
  async created() {
    await this.getDietDetailBasics();
    await this.getDietDetailCompositions();
  },
  beforeUpdate() {
    this.resetSegment();
  },
  methods: {
    toggleDetailsPage(input) {
      this.dietOrHistoryToggle = input;
    },
    resetSegment() {
      this.toggleDetailsPage("diet");
    },

    async exportTuning() {
      await exportTuningCSV(this.$route.params.id);
    },
    async generateReport() {
      await getReport();
    },
    async exportTuningCompo() {
      await exportCompoCSV(this.$route.params.id).then((data)=>{
        if (data.errors){
          openToast(data.errors);
        }else{
          openToast('Tuning history downloaded',3000, 'success');
        }
      });      
    },

    async getDietDetailBasics() {
      const query = `query {
        diet (farmId: ${this.getFarmId}, dietId: ${this.$route.params.id}) {
            name
            prescriptionDate
            optimizationDate
        }
      }`;
      const response = await getData(query);

      if (response) this.data = response.data;
    },
    async getDietDetailCompositions() {
      const query = `query {
        diet (farmId: ${this.getFarmId}, dietId: ${this.$route.params.id}) {
          targetComposition {
            dm {
              lbs
            }
            cp {
              lbs
              pct
            }
            ndf {
              lbs
              pct
            }
            starch {
              lbs
              pct
            }
          }
          prescribedComposition {
            dm {
              lbs
              deviation
            }
            cp {
              lbs
              pct
              deviation
            }
            ndf {
              lbs
              pct
              deviation
            }
            starch {
              lbs
              pct
              deviation
            }
          }
          optimizedComposition {
            dm {
              lbs
              deviation
            }
            cp {
              lbs
              pct
              deviation
            }
            ndf {
              lbs
              pct
              deviation
            }
            starch {
              lbs
              pct
              deviation
            }
          }
        }
      }`;
      const response = await getData(query);

      if (response) this.dataCompositions = response.data;
    },
  },
});
</script>
