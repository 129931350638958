import { modalController } from "@ionic/vue";
import AddDietModal from "@/components/AddDietModal.vue";
import AddIngredientModal from "@/components/AddIngredientToDietModal.vue";
import UpdateNutritionModal from "@/components/UntestedIngredientModal.vue";
import UpdateInclusionModal from "@/components/UpdateInclusionModal.vue";

export default {
  methods: {
    async openModalMixin(component, title, data, fields) {
      const modal = await modalController.create({
        component: component,
        cssClass: "my-custom-modal-css",
        componentProps: {
          title: title,
          data: data,
          fields: fields,
        },
      });
      modal.present();

      return modal;
    },
  },
};
