<template>
  <ion-menu side="end" menu-id="app-menu" content-id="main-content">
    <ion-header>
      <ion-toolbar mode="ios">
        <ion-icon
          :icon="closeOutline"
          @click="closeNavigation()"
          size="large"
        />
        <ion-title>Options</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-item>
          <ion-label color="dark"
            ><em>{{ username }} - {{ getFarmName }}</em></ion-label
          >
        </ion-item>
        <ion-item button @click="logout()" detail="false">
          <ion-label
            color="dark
          "
            ><b>Logout</b></ion-label
          >
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>

<script>
import { defineComponent, ref } from "vue";
import {
  IonMenu,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonItem,
  IonList,
  IonLabel,
  IonIcon,
  menuController,
} from "@ionic/vue";
import { closeOutline } from "ionicons/icons";
import { getData } from "@/utils/api";
import { mapGetters, mapMutations } from "vuex";

export default defineComponent({
  name: "Menu",
  components: {
    IonMenu,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonItem,
    IonList,
    IonLabel,
    IonIcon,
  },
  setup() {
    const username = ref(null);

    return {
      closeOutline,
      username,
    };
  },
  async created() {
    await this.getUsername();
  },
  computed: {
    ...mapGetters("user", ["getUser"]),
    ...mapGetters("farm", ["getFarmName"]),
  },
  methods: {
    ...mapMutations("token", ["setToken"]),
    ...mapMutations("user", ["setUser"]),
    ...mapMutations("loading", ["setIsLoading"]),
    ...mapMutations("farm", ["setFarm"]),
    closeNavigation() {
      menuController.close("app-menu");
    },
    async logout() {
      const mutation = `mutation {
        logout
      }`;
      const {
        data: { logout },
      } = await getData(mutation);

      if (logout) {
        this.setIsLoading(true);
        this.setToken(null);
        this.setUser({});
        this.setFarm({});
        this.closeNavigation();
        this.$router.push("/login");
      }
    },
    getUsername() {
      const user = this.getUser;

      if (user.firstName && user.lastName) {
        this.username = `${user.firstName} ${user.lastName}`;
      } else {
        this.username = user.username;
      }
    },
  },
});
</script>

