<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Diet Tuner</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-card>
        <ion-card-header>
          <ion-card-title color="dark">Login</ion-card-title>
        </ion-card-header>

        <div class="container" v-if="errors">
          <p class="centerChild">
            <b>Incorrect username or password</b>
          </p>
        </div>

        <ion-card-content>
          <ion-label position="stacked" color="dark">Username</ion-label>
          <ion-input v-model="username" class="valuePadding" />
          <ion-label color="dark">Password</ion-label>
          <ion-input v-model="password" type="password" class="valuePadding" />
        </ion-card-content>
        <ion-grid>
          <ion-row class="ion-justify-content-center">
            <ion-col size="auto">
              <ion-button class="greenText" @click="performLogin"
                >Login
                <ion-spinner
                  v-if="loadingLogin"
                  name="crescent"
                  class="loading-spinner"
                />
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, ref } from "vue";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonInput,
  IonLabel,
  IonButton,
  IonGrid,
  IonCol,
  IonRow,
  IonSpinner,
} from "@ionic/vue";
import { openToast } from "@/utils/toast.js";
import { getData, getToken } from "@/utils/api.js";
import { mapGetters, mapMutations } from "vuex";

export default defineComponent({
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonInput,
    IonLabel,
    IonButton,
    IonGrid,
    IonCol,
    IonRow,
    IonSpinner,
  },
  setup() {
    const token = ref();
    const username = ref();
    const password = ref();
    const errors = ref(false);
    const loadingLogin = ref(false);

    return {
      username,
      password,
      token,
      errors,
      loadingLogin,
    };
  },
  async created() {
    await getToken();
  },
  computed: {
    ...mapGetters("user", ["getUser"]),
  },
  methods: {
    ...mapMutations("token", ["setToken"]),
    ...mapMutations("user", ["setUser"]),
    async performLogin() {
      this.loadingLogin = true;
      const query = `mutation login {
        login (
          username: "${this.username}"
          password: "${this.password}"
        ) {
          token
          user {
            id
            username
            firstName
            lastName
          }
        }
      }`;

      const { data, errors } = await getData(query);

      if (data && data.login) {
        this.setToken(data.login.token);
        this.setUser(data.login.user);
        this.$router.push("/home");
      } else {
        this.username = "";
        this.password = "";
        this.loadingLogin = false;

        if (errors) {
          errors.forEach(({ message }) => {
            openToast(message, 2000);
            this.errors = true;
          });
        }
      }
    },
  },
});
</script>
