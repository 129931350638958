import axios from "axios";
import store from "@/store";

const config = {
  baseURL: process.env.VUE_APP_API_DOMAIN,
  withCredentials: true,
};

const $http = axios.create(config);

export const getToken = async () => {
  return $http
    .get(`token/`)
    .then((res) => {
      store.commit("token/setToken", res.data.token);
      return true;
    })
    .catch((err) => {
      console.error(err);

      return err;
    });
};

export const getData = async (query = {}, variables = {}) => {
  const token = store.getters["token/getToken"];
  return $http
    .post(
      `graphql/`,
      {
        query,
        variables,
      },
      {
        headers: {
          "X-CSRFToken": token,
        },
      }
    )
    .then(({ data }) => {
      if (data.errors) {
        console.log("ERROR");
        return {
          data: null,
          errors: data.errors,
        };
      }

      return {
        data: data.data,
        errors: null,
      };
    })
    .catch((err) => {
      console.log("ERR GRAPHQL");
      const { data } = err.response;
      console.error(data.errors);

      return {
        data: null,
        errors: data.errors,
      };
    });
};


//Functionality exposed in button Download Tuning Report (Diet Details/History)
export const getReport = async () => {
  const token = store.getters["token/getToken"];
  const user = store.getters["user/getUser"];
  const farmId = store.getters["farm/getFarmId"];

  axios({
    url: config.baseURL +"/get_report/",
    method: "GET",
    responseType: "blob",
    params:
    {
      "user_id":user.id,
      "farm_id":farmId ,
    },
    headers: {
      "X-CSRFToken": token,
    }
  })
    .then(response  => 
    {
      const blob = new Blob([response.data] );
      const fileName = response.headers["content-disposition"].split("filename=")[1].split(";")[0];          
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
     
      a.href = url;
      a.setAttribute("download", fileName.slice(1,-1));
      document.body.appendChild(a);
      a.click();      
    })
   
    .catch((err) => {
      const { data } = err;      
      console.error(err);

      return {
        data: null,
        errors: data,
      };
    });
};


//Functionality exposed in button Download Tuning Report (Diet Details/History)
export const exportTuningCSV = async (dietId) => {
  const token = store.getters["token/getToken"];
  const user = store.getters["user/getUser"];
  const farmId = store.getters["farm/getFarmId"];

  axios({
    url: config.baseURL +"/get_user_export/",
    method: "GET",
    responseType: "blob",
    params:
    {
      "user_id":user.id,
      "farm_id":farmId,
      "diet_id":dietId,
    },
    headers: {
      "X-CSRFToken": token,
    }
  })
    .then(response  => 
    {      
      const blob = new Blob([response.data], { type: "text/csv" } );
      const fileName = response.headers["content-disposition"].split("filename=")[1];
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", fileName.slice(1,-1));
      a.click();
    })
   
    .catch((err) => {
      const { data } = err;      
      console.error(err);

      return {
        data: null,
        errors: data,
      };
    });
};

//Functionality for tuning composition from user side
export const exportCompoCSV = async (dietId) => {
  const token = store.getters["token/getToken"];
  const user = store.getters["user/getUser"];
  const farmId = store.getters["farm/getFarmId"];

  return axios({
    url: config.baseURL +"/get_tuning_compo/",
    method: "GET",
    responseType: "blob",
    params:
    {
      "user_id":user.id,
      "farm_id":farmId,
      "diet_id":dietId,
    },
    headers: {
      "X-CSRFToken": token,
    }
  })
    .then(response  => 
    { 
      const contentLength = response.headers["content-length"];
      if (parseInt(contentLength)===0 ) {        
        return {
          data:null,
          errors: "No Tuning history"
        };
      }else{        
        const blob = new Blob([response.data], { type: "text/csv" } );
        const fileName = response.headers["content-disposition"].split("filename=")[1];
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("download", fileName.slice(1,-1));
        a.click();
        return{
          data:"OK"
        };
      }
    })
   
    .catch((err) => {
      const { data } = err;      
      console.error(err);

      return {
        data: null,
        errors: data,
      };
    });
};

