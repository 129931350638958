<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>{{ title }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-item-group>
      <ion-item>
        <ion-label color="dark">Ingredient:</ion-label>
        <ion-select
          required
          value="ingredient"
          v-model="ingredient"
          placeholder="Select an Ingredient"
        >
          <ion-select-option
            v-for="ingredient in findUnincludedIngredients"
            :key="ingredient.id"
            :value="ingredient.id"
            class="ingredient-popover"               
            >{{ ingredient.name }}</ion-select-option
          >
        </ion-select>
      </ion-item>
      <div v-for="(field, key) in fields" :key="field">
        <ion-item v-if="field !== 'Ingredient'">
          <ion-label color="dark">{{ field }}:</ion-label>
            <ion-input
              required
              type="number"
              min="0.1"
              step="0.1"
              color="dark"
              @input="updateInput($event, key)"
          />
        </ion-item>
      </div>
    </ion-item-group>
    <div class="container">
      <ion-button @click="addInclusionMutation()" class="child1 greenText"
        >Add Ingredient</ion-button
      >
      <ion-button @click="close()" class="child2 redText">Cancel</ion-button>
    </div>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonItem,
  IonItemGroup,
  IonInput,
  IonButton,
  modalController,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import _ from "lodash";
import { getData } from "@/utils/api";
import { openToast } from "@/utils/toast.js";

export default defineComponent({
  name: "Modal",
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonLabel,
    IonItem,
    IonItemGroup,
    IonInput,
    IonButton,
    IonSelect,
    IonSelectOption
  },
  props: ["title", "data", "fields"],

  setup() {
    const ingredient = ref();
    const modalSubmitResponse = ref({});
    const quantity = ref();
    const minQuantity = ref();
    const maxQuantity = ref();
    const mixingOrder = ref();

    return {
      ingredient,
      quantity,
      minQuantity,
      maxQuantity,
      mixingOrder,
      modalSubmitResponse,
    };
  },
  computed: {
    findUnincludedIngredients() {
      const farmIngredients = this.data.farmIngredients;
      const dietIngredients = this.data.dietInclusions.map((inclusion) => {
        return inclusion.ingredient;
      });

      let unincludedIngredients = _.differenceBy(
        farmIngredients,
        dietIngredients,
        "id"
      );

      unincludedIngredients = _.orderBy(
        unincludedIngredients,
        [(unincludedIngredient) => unincludedIngredient.name],
        ["asc"]
      );
      return unincludedIngredients;
    },
  },
  methods: {
    async submit() {
      await modalController.dismiss({
        ingredient: this.ingredient,
        response: this.modalSubmitResponse,
      });
    },
    async close() {
      await modalController.dismiss();
    },
    async addInclusionMutation() {
      if (
        !this.ingredient ||
        !this.quantity ||
        !this.minQuantity ||
        !this.maxQuantity ||
        !this.mixingOrder
      ) {
        return openToast("Please fill out all fields");
      }

      if (
        parseFloat(this.quantity) < parseFloat(this.minQuantity) ||
        parseFloat(this.maxQuantity) < parseFloat(this.quantity)
      ) {
        return openToast(
          `Quantity is not between min (${this.minQuantity}) and max (${this.maxQuantity}) quantity.`
        );
      }

      if (
        this.quantity < 0.001 ||
        this.minQuantity < 0.001 ||
        this.maxQuantity < 0.001 ||
        this.mixingOrder < 1
      ) {
        this.resetFields();
        return openToast("Values must be greater or equal than 0.001");
      }

      const mutation = `mutation {
        addPrescriptionInclusion (
          input: {
            dietId: ${this.$route.params.id},
            ingredientId: ${this.ingredient},
            quantity: ${ parseFloat(this.quantity)},
            minQuantity: ${parseFloat(this.minQuantity)},
            maxQuantity: ${parseFloat(this.maxQuantity)}
            mixingOrder: ${this.mixingOrder}
          }
        ) {
          id
          quantity
          minQuantity
          maxQuantity
          mixingOrder
        }
      }`;

      const { data, errors } = await getData(mutation);

      if (errors) {
        errors.forEach(({ message }) => {
          openToast(message);
        });
      }

      if (data) {
        this.modalSubmitResponse = data;
        this.clearFields();
        this.submit();
      }
    },
    resetFields() {
      this.quantity = this.inclusion.quantity;
      this.minQuantity = this.inclusion.minQuantity;
      this.maxQuantity = this.inclusion.maxQuantity;
      this.mixingOrder = this.inclusion.mixingOrder;
    },
    clearFields() {
      this.quantity = "";
      this.minQuantity = null;
      this.maxQuantity = null;
      this.mixingOrder = null;
    },
    updateInput(event, key) {
      const input = event.target.value;
      this[key] = input;
    },
  },
});
</script>