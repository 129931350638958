<template>
  <ion-card>
    <ion-card-content>
      <ion-grid class="cards">
        <ion-row>
          <ion-col> {{ ingredientInfo.name }}</ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<script>
import { IonCard, IonCardContent, IonGrid, IonRow, IonCol } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
  },
  setup() {
    return {};
  },
  props: ["ingredientInfo"],
});
</script>