<template>
  <ion-page>
    <template v-if="data">
      <Header :pageTitle="data.diet.name" />

      <ion-content :fullscreen="true">
        <ion-item class="target">
          <ion-grid>
            <ion-row class="ion-justify-content-center ion-align-items-center">
              <ion-col size="auto" class="ion-text-right">
                <ion-label style="font-size: 24px"> DMI:</ion-label>
              </ion-col>
              <ion-col size="3">
                <ion-input
                  type="number"
                  min="0"
                  step="0.001"
                  style="font-size: 24px"
                  class="valuePadding"
                  v-model="DMI"
                  align="center"
                  inputmode="numeric"
                  @change="adjustDMI(this.DMI)"
                />
              </ion-col>
              <ion-col size="auto" style="font-size: 24px">Lbs.</ion-col>
              <ion-col size="auto">
                <ion-button class="blueText" @click="resetDMI()"
                  >Reset</ion-button
                >
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="7" >
                <h5>Ingredients in Formula: {{this.scaledInclusions.length}} </h5>
              </ion-col>
              <ion-col><div class="ion-padding">
                <h5>Tuning Date {{data.diet.optimizationDate}}</h5>
              </div></ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>

        <div class="ion-padding">

           <div class="container">
              <router-link
                class="child1"
                v-bind:to="`/diet/${this.$route.params.id}/edit`"
              >
                <ion-button class="yellowText"> Edit Formula</ion-button>
              </router-link>
              <router-link
                class="child2"
                :to="`/diet/${this.$route.params.id}/detail`"
              >
                <ion-button class="greenText">Formula Details</ion-button>
              </router-link>
            </div>

          <ion-grid class="header">
            <ion-row v-if="data.diet.currentPrescription">
              <ion-col size="6" class="ion-padding-start"><h5>Ingredient</h5></ion-col>
              <ion-col size="3" ><h5>Tuned DM</h5> </ion-col>              
              <ion-col size="3" ><h5> Last Analysis Date</h5> </ion-col>              
            </ion-row>
            <div v-for="inclusion in scaledInclusions" :key="inclusion.id">
              <Inclusion
                :inclusion="inclusion"
                :showDmAndClipboardIconProps="true"
                :showUpdateAndDeleteButtonProps="false"
              />
            </div>

            
          </ion-grid>
        </div>
      </ion-content>
    </template>
  </ion-page>
</template>

<script>
import _ from "lodash";
import { defineComponent, ref } from "vue";
import {
  IonPage,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
} from "@ionic/vue";
import Inclusion from "@/components/InclusionDietSummary";
import Header from "@/components/Header";
import { chevronForwardOutline } from "ionicons/icons";
import { mapGetters } from "vuex";
import { getData } from "@/utils/api";
import loadingMixin from "@/mixins/loading";

export default defineComponent({
  mixins: [loadingMixin],
  name: "DietShow",
  components: {
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonButton,
    Inclusion,
    Header,
  },
  setup() {
    const DMI = ref(0);
    const originalDMI = ref();
    const scaledInclusions = ref([]);
    const data = ref(null);
    return {
      data,
      DMI,
      originalDMI,
      scaledInclusions,
      chevronForwardOutline,
    };
  },
  computed: {
    ...mapGetters("farm", ["getFarmId"]),
  },
  async created() {
    await this.getDietInfo();

    this.setInclusionObjects();
    this.setInitialDMISum();
  },
  methods: {
    async getDietInfo() {
      const query = `query {
        diet (farmId: ${this.getFarmId}, dietId: ${this.$route.params.id}) {
          name
          prescriptionDate
          optimizationDate
          currentPrescription {
            date
            id
            inclusions {
              ingredient {
                name
                id
                dm
                lastAnalysis
              }
              quantity
              adjustedIngredient {
                optimizedQuantity
              }
            }
          }
        }
      }`;
      const response = await getData(query);

      if (response) this.data = response.data;
    },
    setInclusionObjects() {
      if (this.data.diet.currentPrescription) {
        this.scaledInclusions = this.data.diet.currentPrescription.inclusions;        
      }
    },
    setInitialDMISum() {
      if (this.data.diet.currentPrescription) {
        const { inclusions } = this.data.diet.currentPrescription;
        inclusions.forEach((inclusion) => {
          let quantity = inclusion.quantity;

          if (inclusion.adjustedIngredient) {
            const { adjustedIngredient } = inclusion;

            // If value is 0, we want to return it
            if (
              adjustedIngredient.optimizedQuantity ||
              inclusion.adjustedIngredient.optimizedQuantity >= 0
            ) {
              quantity = adjustedIngredient.optimizedQuantity;
            }
          }

          inclusion.originalQuantity = quantity;
          inclusion.calculatedQuantity = quantity;
          this.DMI += quantity;
        });
        this.originalDMI = _.cloneDeep(this.DMI.toLocaleString("en-US", { maximumFractionDigits: 3, minimumFractionDigits: 3 }));
      }
    },
    adjustDMI(DMI) {
      if (DMI && DMI !== "0") {
        this.scaledInclusions.forEach((inclusion) => {
          inclusion.calculatedQuantity = (
            (inclusion.originalQuantity / this.originalDMI) * DMI * 10
             / 10
          ).toFixed(3);
        });
        this.DMI = this.DMI.toLocaleString("en-US", { maximumFractionDigits: 3, minimumFractionDigits: 3 })
      }
    },
    resetDMI() {
      this.adjustDMI(this.originalDMI);
      this.DMI = this.originalDMI;
    },
  },
  watch: {
    DMI: function () {
      this.adjustDMI(this.DMI);
    },
  },
});
</script>
