<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>{{ title }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-item-group>
      <div v-for="(field, key) in fields" :key="field">
        <ion-item v-if="field !== 'Ingredient'"
          ><ion-label color="dark">{{ field }}:</ion-label>
          <ion-item v-if="field !== 'Mixing Order'">
          <ion-input
            required
            type="number"
            :min="0.1"
            step="0.1"
            color="dark"
            @input="updateInput($event, key)"
            :value="data[key]"
        /></ion-item>
        <ion-item v-else-if="field == 'Mixing Order'">
          <ion-input 
            required            
            type="number"
            :min="1"
            step="1"
            color="dark"
            @input="updateInput($event, key)"
            :value="data[key]"
        /></ion-item>
        </ion-item>
      </div>
    </ion-item-group>
    <div class="container">
      <ion-button @click="updateInclusionMutation()" class="child1 yellowText"
        >Update Ingredient</ion-button
      >
      <ion-button @click="close()" class="child2 redText">Cancel</ion-button>
    </div>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonItem,
  IonItemGroup,
  IonInput,
  IonButton,
  modalController,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { openToast } from "@/utils/toast.js";
import { getData } from "@/utils/api";

export default defineComponent({
  name: "Modal",
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonLabel,
    IonItem,
    IonItemGroup,
    IonInput,
    IonButton,
  },
  props: ["title", "data", "fields"],

  setup() {
    const id = ref();
    const quantityChanged = ref(false);
    const quantity = ref();
    const minQuantity = ref();
    const maxQuantity = ref();
    const mixingOrder = ref();
    const options = {
      cssClass: "addIngredientAlert",
    };    
    
    return {
      id,
      quantityChanged,
      quantity,
      minQuantity,
      maxQuantity,
      mixingOrder,
      options,
    };
  },
  methods: {
    setModels() {
      if (this.quantity || this.maxQuantity || this.mixingOrder ||this.minQuantity){  // if any quantity/mixing order changed, we need to display update target buttons 
        this.quantityChanged = true;
      }

      if (!this.quantity)  this.quantity = this.data.quantity;

      if (!this.minQuantity) this.minQuantity = this.data.minQuantity;

      if (!this.maxQuantity) this.maxQuantity = this.data.maxQuantity;

      if (!this.mixingOrder) this.mixingOrder = this.data.mixingOrder;
    },
    async submit() {
      await modalController
        .dismiss({
          quantityChanged: this.quantityChanged,
          ingredientId: this.data.ingredient.id,
          quantity: this.quantity,
          minQuantity: this.minQuantity,
          maxQuantity: this.maxQuantity,
          mixingOrder: this.mixingOrder,
        })
        .then(() => {
          this.clearFields();
        });
    },
    async close() {
      await modalController.dismiss(this.resetFields());
    },
    async updateInclusionMutation() {
      this.setModels();

      if (
        parseFloat(this.quantity) < parseFloat(this.minQuantity) ||
        parseFloat(this.maxQuantity) < parseFloat(this.quantity)
      ) {
        this.resetFields();
        return openToast(
          `Quantity is not between min (${this.minQuantity}) and max (${this.maxQuantity}) quantity.`
        );
      }

      if (
        this.quantity < 0.0001 ||
        this.minQuantity < 0.0001 ||
        this.maxQuantity < 0.0001 ||
        this.mixingOrder < 1
      ) {
        this.resetFields();
        return openToast("Values must be greater than 0.0001");
      }

      const mutation = `mutation {
        updatePrescriptionInclusion (
          input: {
            dietId: ${this.$route.params.id},
            ingredientId: ${this.data.ingredient.id},
            quantity: ${this.quantity},
            minQuantity: ${this.minQuantity},
            maxQuantity: ${this.maxQuantity},
            mixingOrder: ${this.mixingOrder}
          }
        ) {
          id
          quantity
        }
      }`;
      
      const { data, errors } = await getData(mutation);

      if (errors) {
        errors.forEach(({ message }) => {
          openToast(message);
        });
      }

      if (data){
        if (data['updatePrescriptionInclusion'] ){
          this.submit();
          // this.submit().then(() => {
          //   this.valuesChanged = true;
          // });
        } else {
          openToast("Error updating the ingredient, please check your quantities");
        }
      }
    },
    resetFields() {
      this.quantity = this.data.quantity;
      this.minQuantity = this.data.minQuantity;
      this.maxQuantity = this.data.maxQuantity;
      this.mixingOrder = this.data.mixingOrder;
    },
    clearFields() {
      this.ingredient = "";
      this.quantity = null;
      this.minQuantity = null;
      this.maxQuantity = null;
      this.mixingOrder = null;
    },
    updateInput(event, key) {
      const input = event.target.value;
      this[key] = input;
    },
  },
});
</script>