<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>{{ title }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-item-group>
      <ion-item>
        <ion-label color="dark">Diet Name:</ion-label>

        <ion-input placeholder="Enter a Name" color="dark" v-model="dietName" />
      </ion-item>
    </ion-item-group>
    <div class="container">
      <ion-button @click="createDietMutation" class="child1 greenText"
        >Create</ion-button
      >
      <ion-button @click="close()" class="child2 redText">Cancel</ion-button>
    </div>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonItem,
  IonItemGroup,
  IonInput,
  IonButton,
  modalController,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { getData } from "@/utils/api";
import { openToast } from "@/utils/toast.js";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "Modal",
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonLabel,
    IonItem,
    IonItemGroup,
    IonInput,
    IonButton,
  },
  props: ["title", "data"],
  setup() {
    const dietName = ref();

    return {
      dietName,
    };
  },
  computed: {
    ...mapGetters("farm", ["getFarmId"]),
  },
  methods: {
    async submit() {
      await modalController.dismiss();
    },
    async close() {
      await modalController.dismiss();
    },
    async createDietMutation() {
      if (!this.dietName) {
        openToast("Diet must have a name");
      } else {
        const mutation = `mutation {
          createDiet (farmId: ${this.getFarmId}, name: "${this.dietName}") {
            id
            name
          }
        }`;

        const { data, errors } = await getData(mutation);

        if (errors) {
          errors.forEach(({ message }) => {
            openToast(message);
          });
        }

        if (data) {
          this.dietName = "";
          this.$router.go(0);
        }
      }
    },
  },
});
</script>