<template>
  <ion-card>
    <ion-card-content>
      <ion-grid class="cards">
        <ion-row>
          <ion-col size="6" > {{ inclusion.ingredient.name }}</ion-col>
          <ion-col v-if="showDmAndClipboard === showDmAndClipboardIconProps">
            <span class="textWithIcon">
              {{ inclusion.calculatedQuantity }}
              <ion-icon
                :icon="clipboardOutline"
                @click="copyText(inclusion.calculatedQuantity)"
                class="icon"
              />
            </span>
          </ion-col>
          <ion-col class="ion-padding-start"> {{ inclusion.ingredient.lastAnalysis }}</ion-col>
          <div v-if="showUpdateAndDeleteButtons === showUpdateAndDeleteButtonProps">
            <ion-button
              size="small"
              class="yellowText"
              @click="updateInclusion()"
              >Update</ion-button
            >
            <ion-button size="small" @click="deleteInclusion()" class="redText"
              >Remove</ion-button
            >
          </div>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<style scoped>
.icon {
  cursor: pointer;
}
</style>

<script>
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonButton,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { clipboardOutline } from "ionicons/icons";
import { openToast } from "@/utils/toast";

export default defineComponent({
  components: {
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonButton,
  },
  setup() {
    const showDmAndClipboard = ref(true);
    const showUpdateAndDeleteButtons = ref(true);
    return { clipboardOutline, showDmAndClipboard, showUpdateAndDeleteButtons };
  },
  props: [
    "inclusion",
    "inclusionInfo",
    "showDmAndClipboardIconProps",
    "showUpdateAndDeleteButtonProps",
  ],
  methods: {
    copyText(text) {
      navigator.clipboard.writeText(text).then(() => {
        openToast(
          `The value ${text} was copied to your clipboard`,
          2000,
          "dark"
        );
      });
    },
    deleteInclusion() {
      this.$emit("deleteInclusion", this.inclusion);
    },
    updateInclusion() {
      this.$emit("openUpdateInclusionModal", this.inclusion);
    },
  },
});
</script>