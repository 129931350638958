import { toastController } from "@ionic/vue";

export const openToast = async (message, duration = 3000, color = "danger") => {
  const toast = await toastController.create({
    message,
    duration,
    animated: true,
    color,
    position: "top",
  });

  return toast.present();
};
