const state = {
  isLoading: true,
};

const getters = {
  getIsLoading: (state) => {
    return state.isLoading;
  },
};

const actions = {};

const mutations = {
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
