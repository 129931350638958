<template>

  <ion-grid class="header">

 <ion-row class="ion-justify-content-center ion-align-items-center">
      <ion-col size="2"><ion-button class="yellowText" @click="submit()">
        Update Targets
      </ion-button>
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center ion-align-items-center">
      <ion-col />
      <ion-col><h3>Lbs.</h3></ion-col>
      <ion-col ><h3>Percent</h3></ion-col>
    </ion-row>

    <div v-for="(value, key) in targetComposition" :key="key">
      <ion-card>
        <ion-card-content>
          <ion-grid class="cards">
            <ion-row class="ion-justify-content-center ion-align-items-center">
              <ion-col size="2">
                {{ key === "dm" ? "DMI" : key.toUpperCase() }}
              </ion-col>
              <ion-col size="5">
                <ion-item>
                  <ion-input                    
                    required
                    type="number"
                    min="0.1"
                    step="0.1"
                    :readonly="key==='dm'"
                    :value="value.lbs"
                    @input="updateComposition($event, key)"
                  />
                  <ion-label>Lbs.</ion-label>
                </ion-item>
              </ion-col>
              <ion-col size="5">
                <ion-item v-if="value.pct && key != 'dm'">
                  <ion-input                    
                    required
                    type="number"
                    min="0.1"
                    step="0.1"
                    :value="Number(value.pct.replace('%', ''))"
                    @input="reverseUpdateComposition($event, key)"
                  />
                  <ion-label>%</ion-label>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>
    </div>
  </ion-grid>
</template>

<style scoped>
ion-item {
  --background: transparent;
  --padding-start: 0;
  font-weight: bold;
}

ion-input {
  --padding-end: 10px;
  text-align: center;
}
</style>

<script>
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/vue";
import { defineComponent,getCurrentInstance, ref } from "@vue/runtime-core";
import { getData } from "@/utils/api";
import { openToast } from "@/utils/toast.js";
//import { getCurrentInstance } from 'vue';

export default defineComponent({
  name: "Targets",
  components: {
    IonButton,
    IonCard,
    IonCardContent,
    IonCol,
    IonGrid,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
  },
  props: {
    dietId: {
      type: String,
      required: true,
    },
    targetComposition: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const updatedTargets = ref(props.targetComposition);
    const instance = getCurrentInstance();

    const reverseUpdateComposition = (event, nutrient) => {
      const value = event.target.value;
      const dm = updatedTargets.value["dm"]["lbs"];

      updatedTargets.value[nutrient]["lbs"] = (value / 100) * dm;
    };

    const submit = async () => {
      const targetsObject = {
        dmLbs: null,
        cpLbs: null,
        ndfLbs: null,
        starchLbs: null,
      };

      for (const key in targetsObject) {
        const nutrient = key.replace("Lbs", "");

        targetsObject[key] = updatedTargets.value[nutrient]["lbs"];
      }

      /*console.log(`In updatedtargets  dm: ${updatedTargets.value["dm"]["lbs"]} cp: ${updatedTargets.value["cp"]["lbs"]} ndf:${updatedTargets.value["ndf"]["lbs"]} starch:${updatedTargets.value["starch"]["lbs"]}`)
      console.log(`In props.targetsCompo  dm: ${props.targetComposition.dm.lbs} cp: ${props.targetComposition.cp.lbs} ndf:${props.targetComposition.ndf.lbs } starch:${props.targetComposition.starch.lbs}`)
      console.log(`In targetsObject dm: ${targetsObject.dmLbs} cp: ${targetsObject.cpLbs} ndf:${targetsObject.ndfLbs  } starch:${targetsObject.starchLbs}`)
      */     
      const mutation = `mutation {
        updatePrescriptionTargets (
          dietId: ${props.dietId}
          dmLbs: ${targetsObject.dmLbs}
          cpLbs: ${targetsObject.cpLbs}
          ndfLbs: ${targetsObject.ndfLbs}
          starchLbs: ${targetsObject.starchLbs}
        )
      }`;

      const { data, errors } = await getData(mutation);

      if (data) {
        if (data.updatePrescriptionTargets) {   
          //instance.proxy.$emit('syncDate'); hard refresh now DT-228
          await openToast("Targets successfully updated.", 2000, "success");
        }else{
          return openToast("There was a problem updating the targets.", 2000);
        }
      }

      if (errors) {
        errors.forEach(({ message }) => {
          openToast(message, 2000);
        });
      }
    };

    const updateComposition = (event, nutrient) => {
      const value = event.target.value;
      const dm = updatedTargets.value["dm"]["lbs"];
      const updatedPercent = ((value / dm) * 100).toFixed(3);

      updatedTargets.value[nutrient]["lbs"] = Number(value);
      updatedTargets.value[nutrient]["pct"] = `${updatedPercent}%`;
    };

    return {
      reverseUpdateComposition,
      submit,
      updateComposition,
    };
  },
});
</script>