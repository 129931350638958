<template>
  <ion-page>
    <template v-if="data">
      <Header :pageTitle="`Edit ${data.diet.name}`" />
      <ion-content :fullscreen="true">
        <ion-segment mode="md" value="ingredients">
          <ion-segment-button
            @click="toggleEditDietPage('ingredients')"
            value="ingredients"
          >
            <ion-label color="dark">Edit Ingredients</ion-label>
          </ion-segment-button>
          <ion-segment-button
            @click="toggleEditDietPage('targets')"
            value="targets"
          >
            <ion-label color="dark">Edit Targets</ion-label>
          </ion-segment-button>
        </ion-segment>

        <div v-if="ingredientOrTargetToggle === 'ingredients'">
        
          <div class="ion-padding" v-if="data.diet.currentPrescription">
            <ion-grid class="header">
              <ion-row v-if="data.diet.currentPrescription">
                <ion-col size="3" class="ion-padding-start">
                  <h2>Ingredient</h2>
                </ion-col>                
              <ion-col size="4">
                <div>                
                <ion-button
                  class="yellowText centerChild"
                  @click="openAddIngredientModal"
                  >Add Ingredient
                </ion-button>
                <br/>
                Ingredients in Formula: 
                   {{this.inclusions.length}}
              </div>
              </ion-col>
              <ion-col size="4" >
                <div>
                <ion-button
                  class="yellowText centerChild"
                  @click="submitTarget()"
                  :disabled="!this.valuesChanged"
                  >Update Targets
                </ion-button>                
                <br/>
                <ion-label  v-if="data.diet.currentPrescription.lastModified">Last Modified: 
                  {{formatDate(data.diet.currentPrescription.lastModified)}}
                </ion-label>
            </div>
              </ion-col>              
              </ion-row>
              <div v-for="inclusion in inclusions" :key="inclusion">
                <Inclusion
                  :inclusion="inclusion"
                  :showDmAndClipboardIconProps="false"
                  :showUpdateAndDeleteButtonProps="true"
                  @deleteInclusion="handleDeleteInclusion"
                  @openUpdateInclusionModal="openUpdateInclusionModal"
                />
              </div>
            </ion-grid>
          </div>
        </div>

        <div v-show="ingredientOrTargetToggle === 'targets' && data">        
          <Edit-Targets
            :diet-id="data.diet.id"
            :target-composition="targetComposition"
            @syncDate="syncModifiedDate()"
            ref="targetsCompo"
          />
        </div>
        <div v-if="!data.diet.currentPrescription">
          <ion-card>
            <ion-card-content>
              <p>Ability to add an ingredient to a new diet is coming soon</p>
            </ion-card-content>
          </ion-card>
        </div>
      </ion-content>
    </template>
  </ion-page>
</template>


<script>
import { defineComponent, ref } from "vue";
import Header from "@/components/Header.vue";
import Inclusion from "@/components/InclusionDietSummary.vue";
import {
  IonPage,
  IonContent,
  IonRow,
  IonGrid,
  IonCol,
  IonButton,
  IonCard,
  IonCardContent,
  IonSegment,
  IonSegmentButton,
  IonLabel,
} from "@ionic/vue";
import { addCircleOutline } from "ionicons/icons";
import { getData } from "@/utils/api";
import { mapGetters } from "vuex";
import { openToast } from "@/utils/toast";
import _ from "lodash";
import AddIngredientModal from "@/components/AddIngredientToDietModal";
import EditTargets from "@/components/EditTargets";
import UpdateInclusionModal from "@/components/UpdateInclusionModal";
import loadingMixin from "@/mixins/loading";
import modalMixin from "@/mixins/modal";
import { QUANTITIES } from "@/utils/constants";
import formatUtils from "../mixins/formatUtils";

export default defineComponent({
  mixins: [loadingMixin, modalMixin,formatUtils],
  name: "EditDiet",
  components: {
    IonPage,
    IonContent,
    IonRow,
    IonGrid,
    IonCol,
    Header,
    IonButton,
    Inclusion,
    IonCard,
    IonCardContent,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    EditTargets,
  },
  setup() {
    const ingredientOrTargetToggle = ref("ingredients");
    const data = ref(null);
    const farmData = ref(null);
    const targetComposition = ref(null);
    const inclusions = ref([]);
    const inputs = ref({
      ingredient: "Ingredient",
      quantity: "Quantity",
      minQuantity: "Minimum Quantity",
      maxQuantity: "Maximum Quantity",
      mixingOrder: "Mixing Order",
    });
    const quantities = QUANTITIES;
    const valuesChanged = false;

    return {
      ingredientOrTargetToggle,
      addCircleOutline,
      data,
      inclusions,
      targetComposition,
      farmData,
      inputs,
      quantities,
      valuesChanged
    };
  },
  computed: {
    ...mapGetters("farm", ["getFarmId"]),
  },
  async created() {
    await this.getDietInfo();
    await this.getFarmIngredients();
  },
  methods: {
    toggleEditDietPage(input) {      
      if (input ==='ingredients' && this.ingredientOrTargetToggle==='targets'){
        //  this.ingredientOrTargetToggle = input;
        this.$router.go();      
      }else{
        if (this.valuesChanged){
          openToast('Warning:Ingredients were modified, but targets were not updated',3000, "warning")
        }else{
          this.ingredientOrTargetToggle = input;
        }        
      }
    },
    async handleDeleteInclusion(inclusion) {
      if (
        confirm(
          `Are you sure you want to remove ${inclusion.ingredient.name} from this diet?`
        )
      ) {
        const mutation = `mutation {
          removePrescriptionInclusion (
            dietId: ${this.$route.params.id}
            ingredientId: ${inclusion.ingredient.id}
          )
        }`;

        const { data, errors } = await getData(mutation);

        if (data) _.remove(this.inclusions, inclusion);

        if (errors) {
          errors.forEach(({ message }) => {
            openToast(message, 2000);
          });
        }else{
          this.updateTargetsMutation().then(() => {
            this.getDietInfo();
          });
        }
      }
    },
    async getDietInfo() {
      const query = `query {
        diet (farmId: ${this.getFarmId}, dietId: ${this.$route.params.id}) {
          id
          name
          currentPrescription {
            date
            id
            lastModified
            inclusions {
              id
              mixingOrder
              quantity
              minQuantity
              maxQuantity
              ingredient {
                id
                name
                cp
                ndf
                starch
              }

            }
          }
          targetComposition {
            dm {
              lbs
            }
            cp {
              lbs
              pct
            }
            ndf {
              lbs
              pct
            }
            starch {
              lbs
              pct
            }
          }
        }
      }`;
      const response = await getData(query);

      if (response) {
        this.data = response.data;
        const { inclusions } = response.data.diet.currentPrescription;

        this.targetComposition = response.data.diet.targetComposition;
        this.inclusions = inclusions.map((inclusion) => {
          inclusion.quantity = inclusion.quantity.toFixed(3);

          return inclusion;
        });
      }
    },
    async getFarmIngredients() {
      const farmQuery = `query {
        ingredients (farmId: ${this.getFarmId}) {
          id
          name
        }
      }`;
      const farmResponse = await getData(farmQuery);

      if (farmResponse){
        this.farmData = farmResponse.data;
      }else{
        console.log(`Farm data null`);
      }
    },
    async openAddIngredientModal() {
      const { currentPrescription } = this.data.diet;
      const dietInclusions = !currentPrescription
        ? []
        : currentPrescription.inclusions;

      const data = {
        dietInclusions: dietInclusions,
        farmIngredients: this.farmData.ingredients,
      };

      const modal = await this.openModalMixin(
        AddIngredientModal,
        "Add Ingredient",
        data,
        this.quantities
      );

      modal.onDidDismiss().then((modalData) => {
        if (modalData.data) {
          this.addNewIngredientToList(modalData);          
          this.valuesChanged = true;          
        }
      });
    },
    async addNewIngredientToList(modalData) {
      const { data } = modalData;
      /*const ingredient = this.farmData.ingredients.find(
        ({ id }) => id === data.ingredient
      );*/
      const inclusion = data.response
        ? data.response.addPrescriptionInclusion
        : null;

      if (inclusion) {
        /* const newInclusion = {
          id: inclusion.id,
          ingredient: {
            cp: null,
            id: data.ingredient,
            name: ingredient.name,
            ndf: null,
            starch: null,
          },
          maxQuantity: inclusion.maxQuantity,
          minQuantity: inclusion.minQuantity,
          quantity: inclusion.quantity,
          mixingOrder: inclusion.mixingOrder,
        };
        */
        openToast("Ingredient added", 2000, "success");
        this.getDietInfo();
        //await this.getDietInfo();
      }
    },
    async openUpdateInclusionModal(inclusion) {
      const modal = await this.openModalMixin(
        UpdateInclusionModal,
        `Update ${inclusion.ingredient.name}`,
        inclusion,
        this.inputs
      );

      modal.onDidDismiss().then((modalData) => {
        const { data } = modalData;

        if (data) {
          const {
            ingredientId,
            maxQuantity,
            minQuantity,
            mixingOrder,
            quantity,
            quantityChanged,
          } = data;

          this.inclusions = this.inclusions.map((inclusion) => {
            if (inclusion.ingredient.id === ingredientId) {
              return {
                ...inclusion,
                maxQuantity,
                minQuantity,
                mixingOrder,
                quantity,
              };
            }

            return inclusion;
          });

          if (quantityChanged){   
            //this.getDietInfo().then( this.valuesChanged = true) 
            this.valuesChanged = true;           
          }
        }
      });
    },
  
    async updateTargetsMutation() {
          
      let sumQty = 0;
      let sumCP = 0;
      let sumStarch = 0;
      let sumNDF = 0;
      this.inclusions.forEach(element => {
        let currentQty = parseFloat(element.quantity);
        sumQty += currentQty;                
        sumStarch += parseFloat(element.ingredient.starch) * currentQty;
        sumCP += parseFloat(element.ingredient.cp) * currentQty;
        sumNDF += parseFloat(element.ingredient.ndf) * currentQty;
      });
     
      const starchLbs = (sumStarch/100);
      const cpLbs = (sumCP/100);
      const ndfLbs = (sumNDF/100);
      let mutation ="";
      if ( isNaN(cpLbs) && isNaN(ndfLbs) && isNaN(starchLbs) ){
        mutation = `mutation {
          setTargets (
            dietId: ${this.data.diet.id}
          )
        }`;
      }
      else{
        mutation = `mutation {
          updatePrescriptionTargets (
            dietId: ${this.$route.params.id}
            dmLbs: ${sumQty}
            cpLbs: ${cpLbs}
            ndfLbs: ${ndfLbs}
            starchLbs:${starchLbs}
          )
        }`;       
      }

      // console.log(mutation)
      this.valuesChanged = false;

      const { data, errors } = await getData(mutation);
      
      if (errors) {
        errors.forEach(({ message }) => {
          openToast(message);
        });
      }
      
      if (data) {
        openToast("Targets were updated", 2000, "success");
        //this.getDietInfo(); //hardReset now 2t-228
        //this.$forceUpdate(); 
        this.$router.go();
        return true;
      }else{
        return false;
      }

    },


    async syncModifiedDate( ){
      this.getDietInfo().then( (data) => {
        //this.$forceUpdate(); //DT-228
      });
    },

    async submitTarget() {    
      await this.updateTargetsMutation();
    },
  },
});
</script>
