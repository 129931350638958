<template>
  <ion-card>
    <ion-card-content>
      <ion-grid class="cards">
        <ion-row>
          <ion-col>
            {{ nutrientKey === "dm" ? "DMI" : nutrientKey.toUpperCase() }}
          </ion-col>
          <ion-col>
            {{ getComposition("optimizedComposition") }}
            Lbs.
          </ion-col>
          <ion-col>
            {{ getComposition("prescribedComposition") }}
            Lbs.
          </ion-col>
          <ion-col>
            {{ getComposition("targetComposition") }}
            Lbs.
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<script>
import { IonCard, IonCardContent, IonGrid, IonRow, IonCol } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
  },
  setup() {
    return {};
  },
  props: ["nutrient", "nutrientKey", "nutrientData", "composition"],
  methods: {
    getComposition(compositionType, unit = "lbs") {
      const value = this.composition[compositionType][this.nutrientKey][unit];

      if (!value){  return 0;  } 

      if (typeof value === "string") return value;

      return value.toFixed(3);
    },
  },
});
</script>