<template>
  <ion-app>
    <ion-progress-bar
      type="indeterminate"
      v-if="isLoading && $route.name !== 'Login'"
    />
    <ion-router-outlet id="main-content" :key="$route.fullPath" />
    <Menu v-if="!isLoading" />
  </ion-app>
</template>

<style scoped>
ion-progress-bar {
  left: 0;
  position: absolute;
  top: 0;
  z-index: 20;
}
</style>

<script>
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { IonProgressBar } from "@ionic/vue";
import { defineComponent } from "vue";
import { mapState } from "vuex";
import Menu from "@/components/Menu.vue";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonProgressBar,
    IonRouterOutlet,
    Menu,
  },
  computed: {
    ...mapState("loading", ["isLoading"])
  }
});
</script>