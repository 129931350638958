<template>
  <ion-card>
    <ion-card-content>
      <ion-grid class="cards">
        <ion-row>
          <ion-col>
            <b>{{ dietInfo.name }}</b>
          </ion-col>
          <ion-col> {{ dietInfo.prescriptionDate }}</ion-col>
          <ion-col> {{ dietInfo.optimizationDate }}</ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<script>
import { IonCard, IonCardContent, IonGrid, IonRow, IonCol } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
  },
  setup() {
    return {};
  },
  props: ["dietInfo"],
});
</script>