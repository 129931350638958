<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>{{ title }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-item-group>
      <ion-item v-for="(field, key) in fields" :key="key">
        <ion-label color="dark">{{ field }}:</ion-label>
        <ion-input
          type="number"
          min="0"
          max="100"
          step="0.1"
          :value="data.ingredient[key]"
          @input="updateNutrient($event, key)"
          color="dark"
        />
        <span class="whiteText">%</span>
      </ion-item>
    </ion-item-group>
    <div class="container">
      <ion-button @click="submit()" class="child1 greenText">Update</ion-button>
      <ion-button @click="close()" class="child2 redText">Cancel</ion-button>
    </div>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonItem,
  IonItemGroup,
  IonInput,
  IonButton,
  modalController,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Modal",
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonLabel,
    IonItem,
    IonItemGroup,
    IonInput,
    IonButton,
  },
  props: ["title", "data", "fields"],
  setup() {
    const dm = ref();
    const cp = ref();
    const ndf = ref();
    const starch = ref();

    return {
      dm,
      cp,
      ndf,
      starch,
    };
  },
  methods: {
    async submit() {
      await modalController.dismiss({
        dm: this.dm,
        cp: this.cp,
        ndf: this.ndf,
        starch: this.starch,
      });
    },
    async close() {
      await modalController.dismiss();
    },
    updateNutrient(event, key) {
      const input = event.target.value;
      this[key] = input;
    },
  },
});
</script>