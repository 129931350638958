import { createStore, createLogger } from "vuex";
import createPersistedState from "vuex-persistedstate";
import user from "./modules/user";
import token from "./modules/token";
import farm from "./modules/farm";
import loading from "./modules/loading";

const debug = process.env.NODE_ENV !== "production";
const plugins = [createPersistedState()];

export default createStore({
  modules: {
    user,
    token,
    farm,
    loading,
  },
  strict: debug,
  plugins: debug ? [...plugins, createLogger()] : plugins,
});
