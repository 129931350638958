import { mapMutations } from "vuex";

export default {
  watch: {
    data: function () {
      this.setIsLoading(false);
    },
  },
  methods: {
    ...mapMutations("loading", ["setIsLoading"])
  }
};