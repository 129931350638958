<template>
  <ion-page>
    <Header
      :pageTitle="`Diet Tuner ${
        data && data.farm ? '- ' + data.farm.name : ''
      }`"
    />
    <template v-if="data && data.farm">
      <ion-content>
        <ion-segment mode="md" value="diets">
          <ion-segment-button @click="toggleHomePage('diet')" value="diets">
            <ion-label color="dark">Diets</ion-label>
          </ion-segment-button>
          <ion-segment-button
            @click="toggleHomePage('ingredients')"
            value="ingredients"
          >

            <ion-label color="dark">Ingredients</ion-label>
          </ion-segment-button>
        </ion-segment>

        <div class="ion-padding">
          <div v-if="dietOrIngredientToggle === 'diet'">


           <div class="container">
              <ion-button class="greenText centerChild" @click="openModal"
                >Add New Diet
              </ion-button>
            </div>
            <ion-grid class="header">
              <ion-row>
                <ion-col > <h3>Diet </h3></ion-col>
                <ion-col > <h3> Formula Last Updated </h3></ion-col>
                <ion-col > <h3>Last Tuning</h3></ion-col>
              </ion-row>
            </ion-grid>
            <div v-for="diet in data.farm.diets" :key="diet.id">
              <router-link :to="`/diet/${diet.id}`">
                <DietCard :dietInfo="diet" />
              </router-link>
            </div>
           
          </div>

          <div v-if="dietOrIngredientToggle === 'ingredients'">
            <div
              v-for="ingredient in data.farm.ingredients"
              :key="ingredient.id"
            >
              <router-link v-bind:to="`/ingredient/${ingredient.id}`">
                <IngredientCard :ingredientInfo="ingredient" />
              </router-link>
            </div>
          </div>
        </div>
      </ion-content>
    </template>
    <template v-else-if="data && !data.farm">
      <ion-content>
        <div class="ion-padding">This user does not have access to a farm.</div>
      </ion-content>
    </template>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonSegment,
  IonLabel,
  IonSegmentButton,
  IonCol,
  IonPage,
  IonRow,
  IonGrid,
  IonButton,
  modalController,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { addCircleOutline } from "ionicons/icons";
import DietCard from "@/components/DietCard.vue";
import IngredientCard from "@/components/Ingredient.vue";
import { menuController } from "@ionic/vue";
import Header from "@/components/Header.vue";
import { getData } from "@/utils/api";
import { mapGetters, mapMutations } from "vuex";
import AddDietModal from "@/components/AddDietModal.vue";
import loadingMixin from "@/mixins/loading";
import modalMixin from "@/mixins/modal.js";

export default defineComponent({
  mixins: [loadingMixin, modalMixin],
  name: "Home",
  components: {
    IonContent,
    IonSegment,
    IonLabel,
    IonSegmentButton,
    IonCol,
    IonPage,
    IonRow,
    IonGrid,
    DietCard,
    IonButton,
    IngredientCard,
    Header,
  },
  setup() {
    const dietOrIngredientToggle = ref("diet");
    const data = ref(null);

    return {
      addCircleOutline,
      data,
      dietOrIngredientToggle,
    };
  },
  beforeUpdate() {
    this.resetSegment();
  },
  async created() {
    await this.getFarmInfo();
  },
  watch: {
    $route: "getFarmInfo",
  },
  computed: {
    ...mapGetters("user", ["getUser"]),
    ...mapGetters("farm", ["getFarmName", "getFarmId"]),
  },
  methods: {
    ...mapMutations("farm", ["setFarm"]),
    toggleHomePage(input) {
      this.dietOrIngredientToggle = input;
    },
    openMenu() {
      menuController.open("app-menu");
    },
    resetSegment() {
      this.toggleHomePage("diet");
    },
    async getFarmInfo() {
      const query = `query {
        farm {
          name
          id
          diets {
            id
            name
            prescriptionDate
            optimizationDate
          }
          ingredients {
            name
            id
          }
        }
      }`;
      const response = await getData(query);

      if (response) {
        this.data = response.data;

        if (response.data && response.data.farm) {
          this.setFarm({
            name: response.data.farm.name,
            id: response.data.farm.id,
          });
        }
      }
    },
    async openModal() {
      const modal = await this.openModalMixin(AddDietModal, "Add Diet");

      modal.onDidDismiss();
    },
  },
});
</script> 

<style scoped>
</style>